.color {
    color: rgb(64, 69, 69);
}

.subRouteHeading > div{
    font-weight: bold!important;
    font-size:1.1em!important;
}

.subRouteItem{
    margin-left: 10px;
}

.ui.vertical.menu.menu-sidebar,
.menu-sidebar {
    display: inline-flex;
    flex-direction: column;
    border: 0;
    background: #faf9f9;
}

.ui.vertical.menu.menu-sidebar .item {
    box-sizing:border-box;
    border-bottom: none;
    border-right-style: solid;
    border-right-color: transparent;
    border-radius: 0!important;
    border-right-width: 2px;
}

.ui.vertical.menu.menu-sidebar .active.item {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #1B1C1D;
    font-weight: 700;
    color: rgba(0,0,0,.95);
}

.ui.vertical.menu.menu-sidebar .active.item {
    border-color: #9b1c28 !important;
    color: #B31D2C !important;
}

.menu-item-current-user {
    text-align:center;
}


.menu-item-bottom, .ui.vertical.menu.menu-sidebar .menu-item-bottom {
    margin-top:3em;
}

.menu-sidebar-mobile-user {
    border-top-style: solid!important;
    border-top-width: 1px!important;
    border-top-color: rgba(34,36,38,.1)!important;
}

.menu-sidebar-mobile-last {
    margin-bottom:6.5em; /* prevent that bottom navigation bar in safari hides logout button */
    border-bottom: 1px solid rgba(34,36,38,.1)!important;
}

.ui.vertical.menu.menu-sidebar .active.item.menu-sidebar-mobile-user {
    border-top-color: rgba(34,36,38,.1)!important;
}

.menu-sidebar-mobile-language {
    margin-top: 3em;
    text-align: center;
    padding: 1em;
    margin-bottom:1em;
}

.menu-sidebar-mobile-language .languageChangeFlag {
    width: 30% !important;
    height: 30px;
}