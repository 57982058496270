.login-grid {
    height:100%;
    overflow-y: auto;
}

.loginHeading{
    width:100%;
    text-align: center;
}

.languageChangeFlag {
    cursor: pointer;
}