body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__day--keyboard-selected {
  background-color: #B31D2C !important;
}

.react-datepicker__close-icon::after {
  background-color: #B31D2C!important;
}

@media(min-width: 768px) {
  .font-size-small {
    font-size: 0.8em;
  }

  .font-size-large {
    font-size: 1.2em;
  }
}

a, .text-primary {
  color: #B31D2C !important;
}

a:hover {
  color: #6B000A!important;
  text-decoration: underline;
}

thead{
  background-color: rgb(249, 250, 251)!important;
}

tbody {
  /*display: block;*/
  overflow-x: visible;
  width:100%;
}

thead tr, tbody tr {
  /*display: table;*/
  width: calc(100% - 0px);
  /*table-layout: fixed;*/
}

tbody tr {
  width: 100%;
}

*{
  scroll-behavior:auto;
  box-sizing: border-box;
}


.ui.primary.button {
  background-color: #B31D2C !important;
  /*box-shadow: 0 0 0 1px #B31D2C inset !important;*/
}
.ui.primary.button:active {
  background-color: #6B000A !important;
}
.ui.primary.button:hover {
  background-color: #930816 !important;
}

.ui.read.button {
  background-color: #e0e1e2 !important;
  color: rgba(0,0,0,.6) !important;
}
.ui.read.button:active {
  color: black !important;
  background-color: #cacbcd !important;
}
.ui.read.button:hover {
  color: black !important;
  background-color: #cacbcd !important;
}

.ui.edit.button {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px grey inset !important;
  color: grey !important;
}
.ui.edit.button:active {
  color: white !important;
  background-color: black !important;
  box-shadow: 0 0 0 0 !important;
}
.ui.edit.button:hover {
  color: white !important;
  background-color: grey !important;
  box-shadow: 0 0 0 0 !important;
}

.ui.delete.button {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #B31D2C inset !important;
  color: #B31D2C !important;
}
.ui.delete.button:active {
  color: white !important;
  background-color: #6B000A !important;
  box-shadow: 0 0 0 0 !important;
}
.ui.delete.button:hover {
  color: white !important;
  background-color: #B31D2C !important;
  box-shadow: 0 0 0 0 !important;
}

.accordion.ui .title label {
  cursor: pointer;
}


/**
 Scrollbars, Highlighting, ...
 */

::-webkit-selection {
  background-color: #B31D2C;
}

::-moz-selection {
   background-color: #B31D2C;
 }

::selection {
  background-color: #B31D2C;
}

/* thinner scrollbars on mobile devices */
@media(max-width:769px) {
  body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  }
}

/*
  Add fluid option to date picker
 */
.react-datepicker-wrapper {

}

.cm-scroller, .cm-gutters { overflow: auto; min-height: 350px!important; }


.json-form > div > button {
  display: none;
}
