.responsiveBox
{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
    text-align: center;
    max-width:400px;
}

.responsiveBox > img {
    width:250px;
    margin-bottom: 25px;
}

.loading-spinner > svg {
    max-width: 100px;
}

.stroking-svg {
    stroke-dasharray: 2338.5;
    stroke-dashoffset: 2338.5;
    animation: dash2 4s linear infinite;
}
@keyframes stroking-svg {to {stroke-dashoffset: 0;}}

@media(max-width: 500px) {
    .responsiveBox {
        max-width: 350px;
    }

    .responsiveBox > img {
        max-width:90%;
    }
}

