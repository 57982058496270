.app-header-menu {
    z-index:1;
    margin: 0;
    position:relative !important;
    border-radius: 0 !important;
    height:53px;
}

.menu-item-logo {
    background: #ffffff !important;
}

.App-logo {
    width:120px !important;
    padding-left:10px;
    padding-right:30px;
}

.menu-button {
    color:#000000!important;
    background-color: rgb(250,249,249)!important;
    border: 1px solid #909090;
}
