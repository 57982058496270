.app-content{
    padding: 5px;
    padding-bottom:6em;/* prevent that safari bottom bar hides content at the end of the page */

    width:auto;
    overflow: auto;
    /* height: calc(100vh - 15px);*/
    height: calc(100vh - 53px)!important;

    transform: translateZ(0); /* improves scrolling performance  */
}
@media(min-width: 769px) {
    .app-content {
        padding: 2em;
        padding-left: 3em;
        padding-bottom:3em;
    }
}

/*
 * Sticky Table Header on Desktop
 */
@media(min-width:961px) {
    .ui.table thead.sticky-header > tr th {
        position: sticky;
        top:11em; /* .ui.container.sticky-header top + height */
        background:#f9fafb;
    }

    .ui.table thead.sticky-header th.sorted, .ui.table thead.sticky-header th:hover {
        /*background: rgba(0,0,0,.05);*/
        background: #e3e4e5 !important;
    }

    .ui.table thead.sticky-header > tr th {
        border-top: 1px solid  rgb(222,222,222);

        -webkit-box-shadow: none;
        box-shadow: none;
    }

    div.sticky-header {
        top: -2em; /* substract padding of app-content!*/
        position: sticky;
        padding-top:1em; /* readd some padding */
        background:#fff;
        height:13em;
        width:100%;
        z-index: 10;
    }
}

.app-container {
    /*height: calc(100vh - 53px)!important;*/
    width:auto;
    display:block;
    /*overflow:scroll;*/
    overflow: hidden;
}

.ui.segment.pushable {
    margin-top: 0px;
    border-top: 0px;
}

.pushable {
    height: auto!important;
    min-height: calc(100vh - 53px)!important;
    width:auto;
}

@media(min-width:769px) {
    .ui.left.sidebar {
        transition: none !important;
    }

    .ui.visible.left.sidebar {
        width: 260px;
    }

    .ui.left.sidebar ~ .pusher {
        max-width: calc(100vw);
        transition: none !important;
    }

    .ui.visible.left.sidebar ~ .pusher {
        max-width: calc(100vw - 260px);
        transform: translate3d(260px, 0, 0) !important;
    }
}

@media(min-width:769px) {
    .infosys__scroll-to-top-btn {
        margin-bottom: 1em!important;
        margin-right: 1.5em!important;
    }
}